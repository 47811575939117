import React from "react"
import * as Chakra from "@chakra-ui/react"
import { graphql, PageProps, useStaticQuery } from "gatsby"
import Header from "../components/header"
import Footer from "../components/footer"
import "keen-slider/keen-slider.min.css"
import "focus-visible/dist/focus-visible"
import { Global, css } from "@emotion/react"
import "plyr/dist/plyr.css"
import "./layout.scss"
import { SEOContext } from "gatsby-plugin-wpgraphql-seo"
import FadeTransition from "../components/transitions/fade-transition"
import { Helmet } from "react-helmet"

const Layout = ({ children, location }: PageProps): React.ReactElement => {
  /*
      This will hide the focus indicator if the element receives focus via the mouse,
      but it will still show up on keyboard focus.
    */
  const GlobalStyles = css`
    .js-focus-visible :focus:not([data-focus-visible-added]) {
      outline: none;
      box-shadow: none;
    }
  `

  const [isPressLess, setIsPressLess] = React.useState(false)

  React.useEffect(() => {
    if (typeof window !== "undefined" && window.self !== window.top) {
      setIsPressLess(true)
    }
  }, [])

  const {
    wp: { seo },
  } = useStaticQuery(graphql`
    query SiteInfoQuery {
      site {
        buildTime
      }
      wp {
        seo {
          contentTypes {
            post {
              title
              schemaType
              metaDesc
            }
            page {
              metaDesc
              schemaType
              title
            }
          }
          webmaster {
            googleVerify
            yandexVerify
            msVerify
            baiduVerify
          }
          schema {
            companyName
            personName
            companyOrPerson
            wordpressSiteName
            siteUrl
            siteName
            inLanguage
            logo {
              sourceUrl
              mediaItemUrl
              altText
            }
          }
          social {
            facebook {
              url
              defaultImage {
                sourceUrl
                mediaItemUrl
              }
            }
            instagram {
              url
            }
            linkedIn {
              url
            }
            mySpace {
              url
            }
            pinterest {
              url
              metaTag
            }
            twitter {
              username
            }
            wikipedia {
              url
            }
            youTube {
              url
            }
          }
        }
      }
    }
  `)

  return (
    <Chakra.Flex width="100%" flexGrow={1}>

      <SEOContext.Provider value={{ global: seo }}>
        <Global styles={GlobalStyles} />
        <Chakra.Flex
            id="scroll-area"
          minH="100vh"
            width="100%"
          flexDirection="column"
          /*css={{
            // "@media (min-width: 992px)": {
            //   scrollSnapType: isPressLess ? "unset" : "y mandatory",
            //   "@media not all and (min-resolution:.001dpcm)": {
            //     scrollSnapType: "unset!important"
            //   }
            // },
            height: "100vh",
            overflowY: "scroll",
          }}*/
        >
          <Header location={location} />
          <FadeTransition shouldChange={location.pathname}>
            <Chakra.Box width="100%">
              <Chakra.Box width="100%">
              {children}
              </Chakra.Box>
              <Footer location={location} />
            </Chakra.Box>
          </FadeTransition>
        </Chakra.Flex>
      </SEOContext.Provider>
    </Chakra.Flex>
  )
}


export default Layout
