import React from "react"
import * as Chakra from "@chakra-ui/react"
import {graphql, useStaticQuery, Link, navigate} from "gatsby"
import { wordpressFlatMenuToHierarchical } from "../utils/wordpress-flat-menu-to-hierarchical"
import Logo from "../images/logo.svg"
import MenuDrawer from "./menu-drawer"
import ContactDrawer from "./contact-drawer"
import { HeaderState } from "./header-state"
import FadeTransition from "./transitions/fade-transition"

const Header = ({ location }) => {

  const [hasOneNavEvent, setHasOneNavEvent] = React.useState(false)
  const [prevLoc, setPrevLoc] = React.useState(null)

  const data = useStaticQuery(graphql`
    {
      site {
        buildTime
      }
      allWpMenu(filter: { locations: { eq: GATSBY_HEADER_MENU } }) {
        nodes {
          menuItems {
            nodes {
              label
              url
              parentId
              id
            }
          }
        }
      }
      wp {
        acfOptionsOptions {
          siteOptions {
            email
            phoneNumber
            categoryColours {
              categoryName
              colour
              fieldGroupName
            }
          }
        }
      }
    }
  `)

  const menu = React.useMemo(
    () =>
      wordpressFlatMenuToHierarchical(
        data?.allWpMenu?.nodes[0].menuItems?.nodes
      ),
    []
  )

  const {
    isOpen: isMenuOpen,
    onOpen: onMenuOpen,
    onClose: onMenuClose,
  } = Chakra.useDisclosure()

  const showLogo = HeaderState.useGlobalState((gs) => gs.showLogo)

  React.useEffect(() => {

    if (prevLoc) {
      setHasOneNavEvent(true)
    } else {
      setPrevLoc(location.pathname)
    }

  }, [location.pathname])

  return (
    <Chakra.Box
      width="100%"
      display="flex"
      position="sticky"
      alignItems="flex-start"
      flexDirection="column"
      justifyContent="center"
      top="0"
      height="140px"
      minHeight="140px"
      zIndex="99"
      transition={
        location.pathname.includes("works/") ? "none" : "0.5s background-color"
      }
      backgroundColor="#000"
      style={{ backgroundColor: showLogo && !location.pathname.includes("works/") ? "#000f" : "#0000" }}
      css={{
        "@media (max-width: 767px)": {
          height: "100px",
          minHeight: "100px"
        },
      }}
    >
      <Chakra.Box
        pos="relative"
        display="flex"
        width="100%"
        maxWidth="1440px"
        alignSelf="center"
        alignItems="center"
        paddingLeft="58px"
        paddingRight="58px"
        justifyContent="space-between"
        css={{
          "@media (max-width: 991px)": {
            paddingLeft: "24px",
            paddingRight: "24px",
          },
        }}
      >
        <Chakra.Box
          zIndex={3}
          opacity={location.pathname.includes("works/") ? 0 : 1}
          pointerEvents={
            location.pathname.includes("works/") ? "none" : "unset"
          }
          as="span"
          color="#ffffff"
          fontSize="11px"
          fontFamily="TradeGothic LT Extended"
          lineHeight="13.89px"
          letterSpacing="2.06px"
          textTransform="uppercase"
          cursor="pointer"
          onClick={() => {
            HeaderState.showDrawer()
          }}
          css={{
            "@media (max-width: 767px)": {
              fontSize: "10px",
              fontStyle: "normal",
              fontWeight: "700",
              lineHeight: "12.63px",
              letterSpacing: "1.88px",
            },
          }}
        >
          contact
        </Chakra.Box>
        <Chakra.Box
          width="100%"
          left="0"
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Chakra.Box
            objectFit="cover"
            as={Link}
            to="/"
            m="auto"
            width="100%"
            maxWidth="131px"
            css={{
              "@media (max-width: 991px)": {
                //display: "none",
                maxWidth: "90px"
              },
            }}
            maxHeight="131px"
          >
            <Chakra.Box
              transition={
                location.pathname.includes("works/") ? "none" : "0.5s opacity"
              }
              maxWidth="131px"
              width="100%"
              css={{
                "@media (max-width: 991px)": {
                  //display: "none",
                  maxWidth: "90px"
                },
              }}
              opacity={showLogo && !location.pathname.includes("works/") ? 1 : 0}
            >
              <Logo height="100%" width="auto" />
            </Chakra.Box>
          </Chakra.Box>
        </Chakra.Box>

        <Chakra.Box>
          <FadeTransition
            shouldChange={
              location.pathname.includes("works/") ? "#000" : "#ffffff"
            }
          >
            <Chakra.Box
              zIndex={3}
              textAlign="right"
              as="button"
              color={location.pathname.includes("works/") ? "#000" : "#ffffff"}
              fontSize="11px"
              fontFamily="TradeGothic LT Extended"
              lineHeight="13.89px"
              letterSpacing="2.06px"
              textTransform="uppercase"
              cursor="pointer"
              css={{
                "@media (max-width: 767px)": {
                  fontSize: "10px",
                  fontStyle: "normal",
                  fontWeight: "700",
                  lineHeight: "12.63px",
                  letterSpacing: "1.88px",
                },
              }}
              onClick={() => {
                if ( location.pathname.includes("works/")) {
                  if (hasOneNavEvent) {
                    window.history.back()
                  } else {
                    navigate("/")
                  }
                } else {
                  onMenuOpen()
                }
              }}
            >
              {location.pathname.includes("works/") ? "Close" : "Services"}
            </Chakra.Box>
          </FadeTransition>
        </Chakra.Box>
        <MenuDrawer
          isOpen={isMenuOpen}
          onClose={onMenuClose}
          onOpen={onMenuOpen}
          menu={menu}
          siteOptions={data?.wp?.acfOptionsOptions?.siteOptions}
        />
        <ContactDrawer />
      </Chakra.Box>
    </Chakra.Box>
  )
}

export default Header
