import React from "react"
import * as Chakra from "@chakra-ui/react"
import { Link as GatsbyLink } from "gatsby"

interface MenuDrawerProps {
  isOpen: boolean
  onClose: any
  menu: any
  siteOptions: any
}

const MenuDrawer = ({ menu, siteOptions, ...props }: MenuDrawerProps) => {
  const colors: Array<string> = [
    "#5bdcb9",
    "#ffd461",
    "#c089f8",
    "#ff94c0",
    "#9493f0",
    "#feaa6b",
    "#83d7fa",
    "#fff",
  ]

  const {categoryColours} = siteOptions


  //categoryColours[index] && categoryColours[index].categoryName == item.label  ? siteOptions.categoryColours[index].colour :

  return (
    <Chakra.Drawer
      width="100%"
      display="flex"
      flexDirection="column"
      background="#000000"
      h="100vh"
      pos="fixed"
      top="0"
      isOpen={props.isOpen}
      placement="right"
      onClose={props.onClose}
      blockScrollOnMount={false}
    >
      <Chakra.DrawerOverlay />
      <Chakra.DrawerContent
        display="flex"
        width="504px"
        height="100%"
        maxWidth="504px"
        alignItems="flex-end"
        marginLeft="auto"
        paddingTop="50px"
        paddingLeft="58px"
        paddingRight="58px"
        flexDirection="column"
        paddingBottom="77px"
        backgroundColor="#000000"
        position="relative"
        overflowY="auto"
        zIndex="100"
        css={{
          "@media (max-width: 991px)": {
            width: "100%",
            maxWidth: "auto",
            minWidth: "100%",
            paddingTop: "44px",
            paddingLeft: "24px",
            paddingRight: "24px",
            paddingBottom: "98px",
          },
        }}
      >
        <Chakra.Box
          display="flex"
          alignItems="flex-end"
          paddingBottom="78px"
          justifyContent="center"
          css={{
            "@media (max-width: 991px)": {
              width: "100%",
              paddingBottom: "85px",
              justifyContent: "space-between",
              alignItems: "center",
            },
          }}
        >
          <Chakra.Box
            as="span"
            color="#ffffff"
            fontSize="11px"
            fontStyle="normal"
            fontFamily="TradeGothic LT Extended"
            fontWeight="700"
            lineHeight="13.89px"
            letterSpacing="2.06px"
            textTransform="uppercase"
            cursor="pointer"
            display="none"
            css={{
              "@media (max-width: 991px)": {
                display: "unset",
                fontSize: "10px",
                lineHeight: "13px",
                letterSpacing: "1.88px",
              },
            }}
          >
            Services
          </Chakra.Box>
          <Chakra.Box
            as="span"
            color="#ffffff"
            fontSize="11px"
            fontStyle="normal"
            fontFamily="TradeGothic LT Extended"
            fontWeight="700"
            lineHeight="13.89px"
            letterSpacing="2.06px"
            textTransform="uppercase"
            cursor="pointer"
            css={{
              "@media (max-width: 991px)": {
                fontSize: "10px",
                lineHeight: "13px",
                letterSpacing: "1.88px",
              },
            }}
            onClick={props.onClose}
          >
            Close
          </Chakra.Box>
        </Chakra.Box>
        <Chakra.Box
          display="flex"
          width="100%"
          height="calc(100vh - 320px)"
          overflowY="auto"
          alignItems="flex-start"
          flexDirection="column"
          paddingBottom="133px"
          css={{
            "@media (max-width: 991px)": {
              paddingBottom: "240px",
            },
          }}
        >
          {menu.map((item, index) => (
              <Chakra.Link
              as={GatsbyLink}
              key={index}
              to={item.url}
              target="_blank"
              color="#ffffff"
              fontSize="24px"
              transition="0.3s"
              position="relative"
              display="inline-block"
              onClick={props.onClose}
              className="header-link"
              fontFamily="TradeGothic LT Extended"
              lineHeight="46px"
              letterSpacing="3px"
              textTransform="uppercase"
              textDecoration="none"
              _hover={{
                color:   categoryColours.find(x => x.categoryName === item.label) ? categoryColours.find(x => x.categoryName === item.label).colour : "#fff",
                paddingLeft: "7px",
              }}
              css={{
                "@media (max-width: 991px)": {
                  color: `${categoryColours.find(x => x.categoryName === item.label) ? categoryColours.find(x => x.categoryName === item.label).colour : "#fff"}`,
                  fontSize: "26px",
                  lineHeight: "36px",
                  letterSpacing: "1.82px",
                },
              }}
            >
              {item.label}
            </Chakra.Link>
          ))}
        </Chakra.Box>
        <Chakra.Box
          display="flex"
          width="200px"
          alignSelf="flex-start"
          alignItems="flex-start"
          flexDirection="column"
          position={{ base: "relative", lg: "absolute" }}
          bottom={{ base: "0", lg: "77" }}
        >
          <Chakra.Box
            as="span"
            color="#ffffff"
            fontSize="11px"
            fontFamily="TradeGothic LT Extended"
            lineHeight="24px"
            letterSpacing="2.06px"
            textTransform="uppercase"
          >
            {siteOptions.email}
          </Chakra.Box>
          <Chakra.Box
            as="span"
            color="#ffffff"
            fontSize="11px"
            fontFamily="TradeGothic LT Extended"
            lineHeight="24px"
            letterSpacing="2.06px"
            textTransform="uppercase"
          >
            {siteOptions.phoneNumber}
          </Chakra.Box>
        </Chakra.Box>
      </Chakra.DrawerContent>
    </Chakra.Drawer>
  )
}

export default MenuDrawer
