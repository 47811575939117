import React from "react"
import * as Chakra from "@chakra-ui/react"
import { graphql, navigate, useStaticQuery } from "gatsby"
import { HiCheck } from "react-icons/hi"
import { HeaderState } from "./header-state"
import { AiFillCaretDown } from "react-icons/ai"

interface ContactDrawerProps {
  emailInput?: string
  nameInput?: string
  selectInput?: string
  textArea?: string
  submit?: string
  title?: string
  close?: string
  contactInput?: string
}

const ContactDrawer = (props: ContactDrawerProps) => {
  const data = useStaticQuery(graphql`
    {
      site {
        buildTime
      }
      wp {
        acfOptionsOptions {
          siteOptions {
            formContactOptions {
              label
            }
          }
        }
      }
    }
  `)

  const [fullName, setFullName] = React.useState("")
  const [email, setEmail] = React.useState("")
  const [contactNumber, setContactNumber] = React.useState("")
  const [option, setOption] = React.useState("")
  const [message, setMessage] = React.useState("")
  const [isLoading, setIsLoading] = React.useState(false)
  const [isSuccessful, setIsSuccessful] = React.useState(false)

  const encode = (data) => {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
      )
      .join("&")
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    setIsLoading(true)

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": "Contact Form",
        fullName: fullName,
        email: email,
        contactNumber: contactNumber,
        option: option,
        message: message,
        pageUrl: window.location.href,
      }),
    })
      .then((res) => {
        setIsSuccessful(true)

        setTimeout(() => {
          navigate("/thank-you")
          HeaderState.hideDrawer()
        }, 1500)
      })
      .catch((error) => alert(error))
      .finally(() => setIsLoading(false))
  }

  const { onClose } = Chakra.useDisclosure()

  const isOpen = HeaderState.useGlobalState((gs) => gs.showDrawer)

  return (
    <Chakra.Drawer
      width="100%"
      display="flex"
      flexDirection="column"
      background="#000000"
      h="100vh"
      pos="fixed"
      top="0"
      blockScrollOnMount={false}
      isOpen={isOpen}
      placement="left"
      onClose={HeaderState.hideDrawer}
    >
      <Chakra.DrawerOverlay />

      <Chakra.DrawerContent
        width="100%"
        maxWidth="504px"
        display="flex"
        alignItems="flex-start"
        paddingTop="50px"
        paddingLeft="58px"
        paddingRight="58px"
        flexDirection="column"
        overflowY="auto"
        css={{
          "@media (max-width: 991px)": {
            paddingTop: "44px",
            paddingLeft: "24px",
            paddingRight: "24px",
          },
        }}
      >
        <Chakra.Flex
          justify="space-between"
          height="fit-content"
          w="100%"
          pb={{ base: "85px", md: "78px" }}
        >
          <Chakra.Text
            color="#000000"
            fontSize="11px"
            fontFamily="TradeGothic LT Extended"
            lineHeight="14px"
            letterSpacing="2.06px"
            textTransform="uppercase"
            fontWeight="700"
            css={{
              "@media (max-width: 767px)": {
                fontSize: "10px",
                lineHeight: "12.63px",
                letterSpacing: "1.88px",
              },
            }}
          >
            Contact
          </Chakra.Text>
          <Chakra.Button
            color="#000000"
            fontSize="11px"
            fontFamily="TradeGothic LT Extended"
            lineHeight="12px"
            letterSpacing="2px"
            textTransform="uppercase"
            fontWeight="700"
            display="none"
            cursor="pointer"
            onClick={HeaderState.hideDrawer}
            css={{
              "@media (max-width: 767px)": {
                fontSize: "10px",
                lineHeight: "12.63px",
                letterSpacing: "1.8px",
                display: "block",
              },
            }}
          >
            Close
          </Chakra.Button>
        </Chakra.Flex>
        <form
          onSubmit={handleSubmit}
          data-netlify="true"
          name="Contact Form"
          method="post"
        >
          <Chakra.Input
            value={fullName}
            name="fullName"
            onChange={(e) => setFullName(e.target.value)}
            isRequired
            placeholder={props.nameInput}
            color="#000000"
            width="100%"
            height="33px"
            padding="0.5rem 1rem 0.5rem 0"
            fontSize="14px"
            fontWeight="400"
            lineHeight="22px"
            marginBottom="22px"
            cursor="auto"
            border="none"
            borderBottom="1px solid #4A4A4A"
            borderRadius="0"
            _placeholder={{ color: "#000000" }}
          />
          <Chakra.Input
            type="email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            isRequired
            placeholder={props.emailInput}
            color="#000000"
            width="100%"
            height="33px"
            padding="0.5rem 1rem 0.5rem 0"
            fontSize="14px"
            fontWeight="400"
            lineHeight="22px"
            marginBottom="22px"
            cursor="auto"
            border="none"
            borderBottom="1px solid #4A4A4A"
            borderRadius="0"
            _placeholder={{ color: "#000000" }}
          />
          <Chakra.Input
            type="number"
            value={contactNumber}
            name="contactNumber"
            onChange={(e) => setContactNumber(e.target.value)}
            isRequired
            color="#000000"
            placeholder={props.contactInput}
            width="100%"
            minH="33px"
            padding="0.5rem 1rem 0.5rem 0"
            fontSize="14px"
            fontWeight="400"
            lineHeight="22px"
            marginBottom="22px"
            cursor="auto"
            border="none"
            borderBottom="1px solid #4A4A4A"
            borderRadius="0"
            _placeholder={{ color: "#000000" }}
          />
          <Chakra.Select
            icon={
              <Chakra.Box
                width="10px!important"
                marginRight="-28px"
                height="10px!important"
                my="auto"
              >
                <AiFillCaretDown style={{ width: "10px", height: "10px" }} />
              </Chakra.Box>
            }
            sx={{
              textAlignLast: "left",
              paddingInlineStart: 0,
              paddingInlineEnd: 0,
            }}
            name="option"
            onChange={(e) => setOption(e.target.value)}
            isRequired
            placeholder={props.selectInput}
            color="#000000"
            width="100%"
            height="33px"
            fontSize="14px"
            fontWeight="400"
            lineHeight="22px"
            border="none"
            borderBottom="1px solid #4A4A4A"
            borderRadius="0"
            marginBottom="24px"
            cursor="auto"
            _placeholder={{ color: "#000000", width: "100%" }}
          >
            {data?.wp?.acfOptionsOptions?.siteOptions?.formContactOptions?.map(
              ({ label }, i) => (
                <option value={label} style={{ color: "black" }} key={i}>
                  {label}
                </option>
              )
            )}
          </Chakra.Select>
          <Chakra.Textarea
            value={message}
            name="message"
            onChange={(e) => setMessage(e.target.value)}
            placeholder={props.textArea}
            color="#000000"
            width="100%"
            minH="133px"
            fontSize="14px"
            lineHeight="22px"
            border="1px solid #4A4A4A"
            borderRadius="2px"
            cursor="auto"
            padding="0.5rem"
            _placeholder={{ color: "#000000" }}
          />
          <Chakra.Button
            type="submit"
            isDisabled={isLoading || isSuccessful}
            padding="0 1rem"
            d="block"
            ml="auto"
            pr="0"
            fontSize="11px"
            alignSelf="flex-end"
            fontFamily="TradeGothic LT Extended"
            lineHeight="13.89px"
            border="none"
            mb="227px"
            letterSpacing="2.06px"
            textTransform="uppercase"
            color="#000000"
            mt="28px"
            display="inline-block"
            borderColor="var(--dl-color-gray-black)"
            backgroundColor="var(--dl-color-gray-white)"
            css={{
              "@media (max-width: 767px)": {
                marginBottom: "303px",
              },
            }}
          >
            {isSuccessful && (
              <Chakra.Box width="100%" css={{ svg: { margin: "auto" } }}>
                <HiCheck />
              </Chakra.Box>
            )}
            {isLoading && (
              <Chakra.Box width="100%">
                <Chakra.Spinner size="sm" />
              </Chakra.Box>
            )}
            {!isLoading && !isSuccessful && (
              <Chakra.Text>{props.submit}</Chakra.Text>
            )}
          </Chakra.Button>
        </form>
      </Chakra.DrawerContent>
    </Chakra.Drawer>
  )
}

ContactDrawer.defaultProps = {
  emailInput: "Email",
  nameInput: "Your Name",
  selectInput: "I'm getting in touch about",
  textArea: "Your message here",
  submit: "Submit Enquiry",
  title: "Contact",
  close: "close",
  contactInput: "Contact Number",
}
export default ContactDrawer
