import React from "react"
import * as Chakra from "@chakra-ui/react"
import { wordpressFlatMenuToHierarchical } from "../utils/wordpress-flat-menu-to-hierarchical"
import Logo from "../images/logo.svg"

import { graphql, useStaticQuery, Link } from "gatsby"
import { HeaderState } from "./header-state"

const Footer = ({location}) => {
  const data = useStaticQuery(graphql`
    {
        site {
            buildTime
        }
      allWpMenu(filter: { locations: { eq: GATSBY_HEADER_MENU } }) {
        nodes {
          menuItems {
            nodes {
              label
              url
              parentId
              id
            }
          }
        }
      }
      wp {
        acfOptionsOptions {
          siteOptions {
            instagramUrl
            twitterUrl
            vimeoUrl
            email
            phoneNumber
            categoryColours {
              categoryName
              colour
              fieldGroupName
            }
          }
        }
      }
    }
  `)

  const {categoryColours} = data.wp.acfOptionsOptions.siteOptions

  const colors = [
    "#5bdcb9",
    "#ffd461",
    "#c089f8",
    "#ff94c0",
    "#9493f0",
    "#feaa6b",
    "#83d7fa",
    "#fff",
  ]

  const menu = React.useMemo(
    () =>
      wordpressFlatMenuToHierarchical(
        data?.allWpMenu?.nodes[0].menuItems?.nodes
      ).filter(x => x.url !== "/about"),
    []
  )

    let firstMenu = menu.slice(0, Math.ceil(menu.length/2))
    let secondMenu = menu.slice(firstMenu.length, menu.length)

  return (
    <Chakra.Box
        width="100%"
        position="relative"
        alignItems="center"
        paddingTop="80.56px"
        flexDirection="column"
        paddingBottom="101px"
        justifyContent="center"
        display={(location.pathname.includes("works/") ||
        location.pathname.includes("thank-you")) ? "none" : "flex"}
        backgroundColor="var(--dl-color-gray-black)"
        css={{
            "@media (max-width: 767px)": {
                paddingTop: "60.61px",
                paddingBottom: "40px",
            },
        }}
        sx={{
            scrollSnapAlign: "start",
        }}
    >
        <Chakra.Box
            width="100%"
            display="flex"
            maxWidth="1440px"
            alignItems="center"
            marginLeft="auto"
            marginRight="auto"
            paddingLeft="58px"
            paddingRight="58px"
            flexDirection="column"
            justifyContent="center"
            css={{
                "@media (max-width: 767px)": {
                    paddingLeft: "24px",
                    paddingRight: "24px",
                },
            }}
        >
            <Chakra.Box
                width="100%"
                display="flex"
                alignItems="flex-start"
                justifyContent="space-between"
                width="100%"
                css={{
                    "@media (max-width: 991px)": {
                        flexDirection: "column",
                    },
                    "@media (max-width: 767px)": {
                        flexWrap: "wrap",
                        maxWidth: "auto",
                        alignItems: "flex-start",
                        justifyContent: "center",
                    },
                }}
            >
                <Chakra.Box
                    width="200px"
                    display="none"
                    alignItems="flex-start"
                    flexDirection="column"
                    css={{
                        "@media (max-width: 991px)": {
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            marginBottom: "41px",
                            justifyContent: "center",
                        },
                    }}
                >
                    <Chakra.Link
                        href={`mailto:${data?.wp?.acfOptionsOptions?.siteOptions.email}`}
                        target="_blank"
                        rel="noreferrer noopener"
                        color="var(--dl-color-gray-white)"
                        fontSize="11px"
                        alignSelf="center"
                        textAlign="center"
                        fontFamily="Inter"
                        lineHeight="26px"
                        paddingTop="29px"
                        letterSpacing="2.1px"
                        textTransform="uppercase"
                        css={{
                            "@media (max-width: 767px)": {
                                fontStyle: "normal",
                                fontWeight: "700",
                                paddingTop: "0px",
                            },
                        }}
                    >
                        {data?.wp?.acfOptionsOptions?.siteOptions.email}
                    </Chakra.Link>
                    <Chakra.Link
                        href={`tel:${data?.wp?.acfOptionsOptions?.siteOptions.phoneNumber}`}
                        target="_blank"
                        rel="noreferrer noopener"
                        color="var(--dl-color-gray-white)"
                        fontSize="11px"
                        fontStyle="normal"
                        textAlign="center"
                        fontFamily="Inter"
                        fontWeight="700"
                        lineHeight="26px"
                        letterSpacing="2.1px"
                        textTransform="uppercase"
                    >
                        {data?.wp?.acfOptionsOptions?.siteOptions.phoneNumber}
                    </Chakra.Link>
                </Chakra.Box>
                <Chakra.Box
                    width="100%"
                    display="flex"
                    maxWidth="350px"
                    position="relative"
                    alignItems="flex-start"
                    flexDirection="row"
                    justifyContent="space-between"
                    css={{
                        "@media (max-width: 991px)": {
                            width: "100%",
                            maxWidth: "100%",
                            alignItems: "flex-start",
                        },
                        "@media (max-width: 767px)": {
                            width: "100%",
                            maxWidth: "100%",
                            alignSelf: "flex-start",
                            justifyContent: "space-between",
                        },
                    }}
                    zIndex={1}
                >
                    <Chakra.Box>
                    {
                        firstMenu.map((item, index) => (
                            <Chakra.Flex
                                key={index}
                            >
                                <Chakra.Box
                                    as={Link}
                                    width="fit-content"
                                    to={`${item.url}`}
                                    position="relative"
                                    display="block"
                                    _before={{
                                        content: '""',
                                        position: "absolute",
                                        width: "100%",
                                        transform: "scale(0)",
                                        height: "1px",
                                        bottom: "0",
                                        right: "0",
                                        backgroundColor: categoryColours.find(x => x.categoryName === item.label) ? categoryColours.find(x => x.categoryName === item.label).colour : "#fff",
                                        transformOrigin: "bottom right ",
                                        transition: "transform 0.3s ease-out",
                                    }}
                                    color="#fff"
                                    opacity={{ base: "1", md: "1" }}
                                    _hover={{
                                        color: categoryColours.find(x => x.categoryName === item.label) ? categoryColours.find(x => x.categoryName === item.label).colour : "#fff",
                                        opacity: "1",
                                    }}
                                    fontSize="11px"
                                    fontFamily="TradeGothic LT Extended"
                                    lineHeight="28px"
                                    letterSpacing="2.1px"
                                    transition="opacity 0.5s, color 0.5s"
                                    textTransform="uppercase"
                                    textAlign="left"
                                    css={{
                                        ":hover:before": {
                                            transform: "scale(1)",
                                            transformOrigin: "bottom left",
                                        },
                                        "@media (max-width: 767px)": {
                                            fontSize: "10px",
                                            fontStyle: "normal",
                                            fontWeight: "700",
                                            lineHeight: "26px",
                                            letterSpacing: "1.88px",
                                        },
                                    }}
                                >
                                    {item.label}
                                </Chakra.Box>
                            </Chakra.Flex>
                        ))
                    }
                    </Chakra.Box>
                    <Chakra.Box ml="70px">
                    {
                        secondMenu.map((item, index) => (
                            <Chakra.Flex
                                key={index}
                                css={{
                                    "@media (max-width: 992px)": {
                                        justifyContent: "flex-end"
                                    },
                                }}
                            >
                                <Chakra.Box
                                    as={Link}
                                    width="fit-content"
                                    to={`${item.url}`}
                                    position="relative"
                                    display="block"
                                    _before={{
                                        content: '""',
                                        position: "absolute",
                                        width: "100%",
                                        transform: "scale(0)",
                                        height: "1px",
                                        bottom: "0",
                                        right: "0",
                                        backgroundColor: categoryColours.find(x => x.categoryName === item.label) ? categoryColours.find(x => x.categoryName === item.label).colour : "#fff",
                                        transformOrigin: "bottom right ",
                                        transition: "transform 0.3s ease-out",
                                    }}
                                    color="#fff"
                                    opacity={{ base: "1", md: "1" }}
                                    _hover={{
                                        color: categoryColours.find(x => x.categoryName === item.label) ? categoryColours.find(x => x.categoryName === item.label).colour : "#fff",
                                        opacity: "1",
                                    }}
                                    fontSize="11px"
                                    fontFamily="TradeGothic LT Extended"
                                    lineHeight="28px"
                                    letterSpacing="2.1px"
                                    transition="opacity 0.5s, color 0.5s"
                                    textTransform="uppercase"
                                    css={{
                                        ":hover:before": {
                                            transform: "scale(1)",
                                            transformOrigin: "bottom left",
                                        },
                                        "@media (max-width: 992px)": {
                                            textAlign: "right"
                                        },
                                        "@media (max-width: 767px)": {
                                            fontSize: "10px",
                                            fontStyle: "normal",
                                            fontWeight: "700",
                                            lineHeight: "26px",
                                            letterSpacing: "1.88px",

                                        },
                                    }}
                                >
                                    {item.label}
                                </Chakra.Box>
                            </Chakra.Flex>
                        ))
                    }
                    </Chakra.Box>
                </Chakra.Box>
                <Chakra.Box
                    display="flex"
                    alignItems="center"
                    flexDirection="column"
                    justifyContent="center"
                    position="absolute"
                    left="0"
                    width="100%"
                    css={{
                        "@media (max-width: 991px)": {
                            width: "100%",
                            display: "none",
                            maxWidth: "250px",
                            paddingLeft: "20px",
                            paddingRight: "-3px",
                        },
                    }}
                >
                    <Chakra.Box width="131px">
                        <Logo width="100%" />
                    </Chakra.Box>
                    <Chakra.Link
                        href={`mailto:${data?.wp?.acfOptionsOptions?.siteOptions.email}`}
                        rel="noreferrer noopener"
                        color="var(--dl-color-gray-white)"
                        fontSize="11px"
                        alignSelf="center"
                        textAlign="center"
                        fontFamily="Inter"
                        lineHeight="26px"
                        paddingTop="29px"
                        letterSpacing="2.1px"
                        textTransform="uppercase"
                        position="relative"
                        display="inline-block"
                        _hover={{ textDecoration: "none", opacity: "0.6" }}
                        _before={{
                            content: '""',
                            position: "absolute",
                            width: "100%",
                            transform: "scale(0)",
                            height: "1px",
                            bottom: "0",
                            right: "0",
                            backgroundColor: "#fff",
                            opacity: "0.6",
                            transformOrigin: "bottom right ",
                            transition: "transform 0.3s ease-out",
                        }}
                        css={{
                            ":hover:before": {
                                transform: "scale(1)",
                                transformOrigin: "bottom left",
                            },
                        }}
                    >
                        {data?.wp?.acfOptionsOptions?.siteOptions.email}
                    </Chakra.Link>
                    <Chakra.Link
                        href={`tel:${data?.wp?.acfOptionsOptions?.siteOptions.phoneNumber}`}
                        rel="noreferrer noopener"
                        color="var(--dl-color-gray-white)"
                        fontSize="11px"
                        fontStyle="normal"
                        textAlign="center"
                        fontFamily="Inter"
                        fontWeight="700"
                        lineHeight="26px"
                        letterSpacing="2.1px"
                        textTransform="uppercase"
                        position="relative"
                        display="inline-block"
                        _hover={{ textDecoration: "none", opacity: "0.6" }}
                        _before={{
                            content: '""',
                            position: "absolute",
                            width: "100%",
                            transform: "scale(0)",
                            height: "1px",
                            bottom: "0",
                            right: "0",
                            backgroundColor: "#fff",
                            opacity: "0.6",
                            transformOrigin: "bottom right ",
                            transition: "transform 0.3s ease-out",
                        }}
                        css={{
                            ":hover:before": {
                                transform: "scale(1)",
                                transformOrigin: "bottom left",
                            },
                        }}
                    >
                        {data?.wp?.acfOptionsOptions?.siteOptions.phoneNumber}
                    </Chakra.Link>

                </Chakra.Box>
                <Chakra.Box
                    width="100%"
                    display="flex"
                    maxWidth="322px"
                    alignItems="flex-start"
                    flexDirection="row"
                    position="relative"
                    zIndex="5"
                    justifyContent="space-between"
                    css={{
                        "@media (max-width: 991px)": {
                            width: "100%",
                            maxWidth: "100%",
                            marginTop: "38px",
                            justifyContent: "space-between",
                        },
                    }}
                >
                    <Chakra.Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                        w="100%"
                        css={{
                            "@media (max-width: 991px)": {
                                maxWidth: "100%",
                            },
                        }}
                    >
                        <Chakra.Flex direction="column" w="45%">
                            <Chakra.Box
                                as={Link}
                                to="/about"
                                position="relative"
                                w="fit-content"
                                _before={{
                                  content: '""',
                                  position: "absolute",
                                  width: "100%",
                                  transform: "scale(0)",
                                  height: "1px",
                                  bottom: "0",
                                  right: "0",
                                  backgroundColor: "#fff",
                                  opacity: "0.6",
                                  transformOrigin: "bottom right ",
                                  transition: "transform 0.3s ease-out",
                                }}
                                color="var(--dl-color-gray-white)"
                                fontSize="11px"
                                fontFamily="TradeGothic LT Extended"
                                lineHeight="28px"
                                letterSpacing="2.1px"
                                _hover={{ opacity: "0.6" }}
                                transition="opacity 0.5s"
                                textTransform="uppercase"
                                ml="auto"
                                textAlign="right"
                                display="none"
                                css={{
                                  ":hover:before": {
                                      transform: "scale(1)",
                                      transformOrigin: "bottom left",
                                  },
                                  "@media (max-width: 991px)": {
                                    textAlign: "left",
                                    marginLeft: 0,
                                      display: "inline-block"
                                  },
                                  "@media (max-width: 767px)": {
                                      fontSize: "10px",
                                      fontStyle: "normal",
                                      fontWeight: "700",
                                      lineHeight: "26px",
                                      letterSpacing: "1.88px",

                                  },
                                }}
                            >
                              About
                            </Chakra.Box>
                            <Chakra.Box
                                display="none"
                                cursor="pointer"
                                onClick={() => HeaderState.showDrawer()}
                                color="var(--dl-color-gray-white)"
                                position="relative"
                                w="fit-content"
                                _before={{
                                    content: '""',
                                    position: "absolute",
                                    width: "100%",
                                    transform: "scale(0)",
                                    height: "1px",
                                    bottom: "0",
                                    right: "0",
                                    backgroundColor: "#fff",
                                    opacity: "0.6",
                                    transformOrigin: "bottom right ",
                                    transition: "transform 0.3s ease-out",
                                }}
                                fontSize="11px"
                                fontFamily="TradeGothic LT Extended"
                                lineHeight="28px"
                                _hover={{ opacity: "0.6" }}
                                transition="opacity 0.5s"
                                letterSpacing="2.1px"
                                textTransform="uppercase"
                                ml="auto"
                                textAlign="right"
                                css={{
                                    ":hover:before": {
                                        transform: "scale(1)",
                                        transformOrigin: "bottom left",
                                    },
                                    "@media (max-width: 991px)": {
                                        textAlign: "left",
                                        marginLeft: 0,
                                        display: "inline-block"
                                    },
                                    "@media (max-width: 767px)": {
                                        fontSize: "10px",
                                        fontStyle: "normal",
                                        fontWeight: "700",
                                        lineHeight: "26px",
                                        letterSpacing: "1.88px",

                                    },
                                }}
                            >
                              Contact
                            </Chakra.Box>
                        </Chakra.Flex>
                        <Chakra.Flex direction="column" w="45%">
                            <Chakra.Box
                                as={Link}
                                to="/about"
                                position="relative"
                                w="fit-content"
                                display="inline-block"
                                _before={{
                                    content: '""',
                                    position: "absolute",
                                    width: "100%",
                                    transform: "scale(0)",
                                    height: "1px",
                                    bottom: "0",
                                    right: "0",
                                    backgroundColor: "#fff",
                                    opacity: "0.6",
                                    transformOrigin: "bottom right ",
                                    transition: "transform 0.3s ease-out",
                                }}
                                color="var(--dl-color-gray-white)"
                                fontSize="11px"
                                fontFamily="TradeGothic LT Extended"
                                lineHeight="28px"
                                letterSpacing="2.1px"
                                _hover={{ opacity: "0.6" }}
                                transition="opacity 0.5s"
                                textTransform="uppercase"
                                ml="auto"
                                textAlign="right"
                                css={{
                                    ":hover:before": {
                                        transform: "scale(1)",
                                        transformOrigin: "bottom left",
                                    },
                                    "@media (max-width: 991px)": {
                                        marginLeft: 0,
                                        display: "none"
                                    },
                                    "@media (max-width: 767px)": {
                                        fontSize: "10px",
                                        fontStyle: "normal",
                                        fontWeight: "700",
                                        lineHeight: "26px",
                                        letterSpacing: "1.88px",

                                    },
                                }}
                            >
                                About
                            </Chakra.Box>
                            <Chakra.Box
                                cursor="pointer"
                                onClick={() => HeaderState.showDrawer()}
                                color="var(--dl-color-gray-white)"
                                position="relative"
                                display="inline-block"
                                w="fit-content"
                                _before={{
                                    content: '""',
                                    position: "absolute",
                                    width: "100%",
                                    transform: "scale(0)",
                                    height: "1px",
                                    bottom: "0",
                                    right: "0",
                                    backgroundColor: "#fff",
                                    opacity: "0.6",
                                    transformOrigin: "bottom right ",
                                    transition: "transform 0.3s ease-out",
                                }}
                                fontSize="11px"
                                fontFamily="TradeGothic LT Extended"
                                lineHeight="28px"
                                _hover={{ opacity: "0.6" }}
                                transition="opacity 0.5s"
                                letterSpacing="2.1px"
                                textTransform="uppercase"
                                ml="auto"
                                textAlign="right"
                                css={{
                                    ":hover:before": {
                                        transform: "scale(1)",
                                        transformOrigin: "bottom left",
                                    },
                                    "@media (max-width: 991px)": {
                                        marginLeft: 0,
                                        display: "none"
                                    },
                                    "@media (max-width: 767px)": {
                                        fontSize: "10px",
                                        fontStyle: "normal",
                                        fontWeight: "700",
                                        lineHeight: "26px",
                                        letterSpacing: "1.88px",

                                    },
                                }}
                            >
                                Contact
                            </Chakra.Box>
                            {data.wp.acfOptionsOptions.siteOptions.instagramUrl && (
                                <Chakra.Link
                                    ml="auto"
                                    textAlign="right"
                                    textDecoration="none"
                                    w="fit-content"
                                    color="var(--dl-color-gray-white)"
                                    fontSize="11px"
                                    fontFamily="TradeGothic LT Extended"
                                    lineHeight="28px"
                                    letterSpacing="2.1px"
                                    _hover={{ opacity: "0.6" }}
                                    cursor="pointer"
                                    target="_blank"
                                    position="relative"
                                    display="block"
                                    _before={{
                                        content: '""',
                                        position: "absolute",
                                        width: "100%",
                                        transform: "scale(0)",
                                        height: "1px",
                                        bottom: "0",
                                        right: "0",
                                        backgroundColor: "#fff",
                                        opacity: "0.6",
                                        transformOrigin: "bottom right ",
                                        transition: "transform 0.3s ease-out",
                                    }}
                                    href={data.wp.acfOptionsOptions.siteOptions.instagramUrl}
                                    transition="opacity 0.5s"
                                    textTransform="uppercase"
                                    css={{
                                        ":hover:before": {
                                            transform: "scale(1)",
                                            transformOrigin: "bottom left",
                                        },
                                        "@media (max-width: 767px)": {
                                            fontSize: "10px",
                                            fontStyle: "normal",
                                            fontWeight: "700",
                                            lineHeight: "26px",
                                            letterSpacing: "1.88px",
                                        },
                                    }}
                                >
                                    Instagram
                                </Chakra.Link>
                            )}
                            {data.wp.acfOptionsOptions.siteOptions.vimeoUrl && (
                                <Chakra.Link
                                    ml="auto"
                                    textAlign="right"
                                    w="fit-content"
                                    textDecoration="none"
                                    color="var(--dl-color-gray-white)"
                                    fontSize="11px"
                                    fontFamily="TradeGothic LT Extended"
                                    lineHeight="28px"
                                    target="_blank"
                                    position="relative"
                                    display="block"
                                    _before={{
                                    content: '""',
                                    position: "absolute",
                                    width: "100%",
                                    transform: "scale(0)",
                                    height: "1px",
                                    bottom: "0",
                                    right: "0",
                                    backgroundColor: "#fff",
                                    opacity: "0.6",
                                    transformOrigin: "bottom right ",
                                    transition: "transform 0.3s ease-out",
                                    }}
                                    href={data.wp.acfOptionsOptions.siteOptions.vimeoUrl}
                                    _hover={{ opacity: "0.6" }}
                                    transition="opacity 0.5s"
                                    cursor="pointer"
                                    letterSpacing="2.1px"
                                    textTransform="uppercase"
                                    css={{
                                    ":hover:before": {
                                        transform: "scale(1)",
                                        transformOrigin: "bottom left",
                                    },
                                    "@media (max-width: 767px)": {
                                        fontSize: "10px",
                                        fontStyle: "normal",
                                        fontWeight: "700",
                                        lineHeight: "26px",
                                        letterSpacing: "1.88px",
                                    },
                                    }}
                                >
                                Vimeo
                                </Chakra.Link>
                            )}
                            {data.wp.acfOptionsOptions.siteOptions.twitterUrl && (
                                <Chakra.Link
                                    ml="auto"
                                    textAlign="right"
                                    w="fit-content"
                                    textDecoration="none"
                                    href={data.wp.acfOptionsOptions.siteOptions.twitterUrl}
                                    color="var(--dl-color-gray-white)"
                                    fontSize="11px"
                                    fontFamily="TradeGothic LT Extended"
                                    lineHeight="28px"
                                    target="_blank"
                                    position="relative"
                                    display="block"
                                    _before={{
                                    content: '""',
                                    position: "absolute",
                                    width: "100%",
                                    transform: "scale(0)",
                                    height: "1px",
                                    bottom: "0",
                                    right: "0",
                                    backgroundColor: "#fff",
                                    opacity: "0.6",
                                    transformOrigin: "bottom right ",
                                    transition: "transform 0.3s ease-out",
                                    }}
                                    letterSpacing="2.1px"
                                    _hover={{ opacity: "0.6" }}
                                    transition="opacity 0.5s"
                                    cursor="pointer"
                                    textTransform="uppercase"
                                    css={{
                                    ":hover:before": {
                                        transform: "scale(1)",
                                        transformOrigin: "bottom left",
                                    },
                                    "@media (max-width: 767px)": {
                                        fontSize: "10px",
                                        fontStyle: "normal",
                                        fontWeight: "700",
                                        lineHeight: "26px",
                                        letterSpacing: "1.88px",
                                    },
                                    }}
                                >
                                Twitter
                                </Chakra.Link>
                            )}
                        </Chakra.Flex>
                    </Chakra.Box>
                </Chakra.Box>
            </Chakra.Box>

            {/*<Chakra.Box
                width="100%"
                left="0"
                fontSize="9px"
                fontStyle="normal"
                fontFamily="TradeGothic LT Extended"
                fontWeight="700"
                lineHeight="20px"
                letterSpacing="1.4px"
                textTransform="uppercase"
                textAlign="center"
                display="none"
                css={{
                    "@media (max-width: 991px)": {
                        paddingTop: "60px",
                        order: 0,
                        width: "100%",
                        flexShrink: "0",
                        display: "initial"
                    }
                }}
            >
               Stories X Art X Heart
            </Chakra.Box>*/}
            <Chakra.Box
                width="100%"
                display="flex"
                marginTop="30px"
                alignItems="flex-start"
                justifyContent="center"
                pt="45px"
                css={{
                    "@media (max-width: 991px)": {
                        height: "auto",
                        marginTop: "0px",
                        justifyContent: "center",
                        flexWrap: "wrap",
                        paddingTop: "15px",
                    },
                }}
            >
                <Chakra.Box
                    color="var(--dl-color-gray-white)"
                    fontSize="10px"
                    fontStyle="normal"
                    fontWeight="400"
                    lineHeight="20px"
                    textAlign="center"
                    w="100%"
                    maxW="340px"
                    letterSpacing="1.11px"
                    css={{
                        "@media (max-width: 991px)": {
                            order: 2,
                            maxWidth: "fit-content",
                        },
                        "@media (max-width: 767px)": {
                            color: "var(--dl-color-gray-white)",
                            fontSize: "10px",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "20px",
                            letterSpacing: "1.11px",
                        },
                        "@media (max-width: 479px)": {
                            marginRight: "15px",
                        },
                    }}
                >
                    © Sutori {new Date().getFullYear()} All Rights Reserved.
                </Chakra.Box>
                {/*<Chakra.Box
                    width="100%"
                    left="0"
                    position="absolute"
                    fontSize="9px"
                    fontStyle="normal"
                    fontFamily="TradeGothic LT Extended"
                    fontWeight="700"
                    lineHeight="20px"
                    letterSpacing="1.4px"
                    textTransform="uppercase"
                    textAlign="center"
                    css={{
                        "@media (max-width: 991px)": {
                            order: 1,
                            width: "100%",
                            flexShrink: "0",
                            marginBottom: "8px",
                            bottom: "8px",
                            display: "none"
                        }
                    }}
                >
                    Stories X Art X Heart
                </Chakra.Box>*/}
                {/*<Chakra.Box
                    w="100%"
                    maxW="322px"
                    css={{
                        "@media (max-width: 991px)": {
                            order: 3,
                            maxWidth: "fit-content",
                        }
                    }}
                >
                    <Chakra.Link
                        w="fit-content"
                        ml="auto"
                        href="https://atollon.com.au/"
                        target="_blank"
                        rel="noreferrer noopener"
                        color="var(--dl-color-gray-white)"
                        fontSize="10px"
                        fontStyle="normal"
                        position="relative"
                        display="block"
                        _hover={{ textDecoration: "none", opacity: "0.6" }}
                        _before={{
                            content: '""',
                            position: "absolute",
                            width: "100%",
                            transform: "scale(0)",
                            height: "1px",
                            bottom: "0",
                            right: "0",
                            backgroundColor: "#fff",
                            opacity: "0.6",
                            transformOrigin: "bottom right ",
                            transition: "transform 0.3s ease-out",
                        }}
                        css={{
                            ":hover:before": {
                                transform: "scale(1)",
                                transformOrigin: "bottom left",
                            },
                        }}
                        zIndex="5"
                        textAlign="right"
                        fontWeight="400"
                        lineHeight="20px"
                        letterSpacing="1.11px"
                        fontFamily="Inter"
                    >
                        Design by Atollon
                    </Chakra.Link>
                </Chakra.Box>*/}
            </Chakra.Box>
        </Chakra.Box>
    </Chakra.Box>
  )
}

export default Footer
