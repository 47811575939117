import { GlobalState } from "react-gstate"
import * as Chakra from "@chakra-ui/react"
import React, { useState, useEffect } from "react"

class HeaderStateMachine extends GlobalState<any> {
  showLogo = () => {
    HeaderState.setState({ showLogo: true })
  }

  hideLogo = () => {
    HeaderState.setState({ showLogo: false })
  }

  hideDrawer = () => {
    HeaderState.setState({ showDrawer: false })
  }

  showDrawer = () => {
    HeaderState.setState({ showDrawer: true })
  }
}

export const HeaderState = new HeaderStateMachine({
  showLogo: false,
  showDrawer: false,
})
